import React from "react";
import growing from "../../../assets/growing.svg";
import recognize from "../../../assets/recognize.svg";
import reward from "../../../assets/reward.svg";

function AboutSection() {
  const handleClick = (id) => {
    const section = document.getElementById(id);
    window.scrollTo({
      top: section.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <section
      className="benefit__section max-w-4xl grid gap-10 mx-auto relative"
      id="about"
    >
      <div className="bg-rose-200 flex flex-col md:flex-row gap-y-2 py-10 md:pt-10 lg:rounded-xl relative">
        <div className="hidden lg:block absolute top-0 left-40 w-[408px] h-[408px] bg-rose-700 rounded-full blur-[300px]" />
        <div className="w-full lg:w-1/2 hidden md:flex">
          <img src={growing} alt="" className="w-[60rem] h-fit" />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-y-2 items-start justify-center px-20 lg:pl-0 ">
          <div className="bg-white/20 backdrop-blur-md h-8 w-8 rounded-full"></div>
          <h2 className="text-2xl md:text-3xl lg:text-4xl text-rose-900 font-bold">
            Crescendo com você
          </h2>
          <p className="max-w-sm mx-0 text-rose-900">
            Na Elimacol, sabemos que só podemos crescer se formos parceiros de
            nossos parceiros. Dessa forma, com essa iniciativa, esperamos
            impactar positivamente o desenvolvimento da sua carreira.
          </p>
        </div>
      </div>

      <div className="bg-rose-500 flex py-10 md:pt-10 lg:rounded-xl relative">
        <div className="hidden lg:block absolute top-0 left-0 w-[408px] h-[408px]  bg-opacity-50 rounded-full blur-[300px]" />
        <div className="w-full lg:w-1/2 flex flex-col gap-y-2 items-start justify-center px-20 ">
          <div className="bg-white/20 backdrop-blur-md h-8 w-8 rounded-full"></div>
          <h2 className="text-2xl md:text-3xl lg:text-4xl text-white font-bold">
            Reconhecer o seu trabalho
          </h2>
          <p className="max-w-sm mx-0 text-white">
            Queremos reconhecer, apoiar e investir no crescimento profissional
            de arquitetos, desigers de interiores e engenheiros que utilizam os
            serviços prestados pela Elimacol.
          </p>
        </div>
        <div className="w-full lg:w-1/2 hidden md:flex justify-end">
          <img src={recognize} alt="" />
        </div>
      </div>

      <div className="bg-rose-100 flex py-10 md:pt-10 lg:rounded-xl relative">
        <div className="hidden lg:block absolute top-0 left-40 w-[408px] h-[408px] rounded-full blur-[300px]" />
        <div className="w-full lg:w-1/2 flex flex-col gap-y-2 items-start justify-center px-20 ">
          <div className="bg-white/20 backdrop-blur-md h-8 w-8 rounded-full"></div>
          <h2 className="text-2xl md:text-3xl lg:text-4xl text-rose-900 font-bold">
            Premiar a sua parceiria
          </h2>
          <p className="max-w-sm mx-0 text-rose-900">
            O Floresça terá duração de 1 ano, contatados entre 01/01/2025 a
            31/12/2025. No final desse período, serão distribuídos prêmios que o
            ajudarão a desenvolver a sua carreira.
          </p>
          <a
            onClick={() => {
              handleClick("prizes");
            }}
            className="!bg-rose-900 !text-rose-100 !font-medium !px-5 !py-2 !mt-4 !rounded-xl !shadow-md hover:!opacity-80 cursor-pointer"
          >
            Conheça os prêmios
          </a>
        </div>
        <div className="w-full lg:w-1/2 hidden md:flex items-end justify-end">
          <img src={reward} alt="" />
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
