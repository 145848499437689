import React from "react";

const prizesData = [
  {
    testimonial: "Viagem para Expo Revestir 2026",
    description:
      "É a maior feira em matéria de revestimentos e acabamentos da América Látina. Durante o evento são apresentadas novas tendências e lançamentos para o setor. É uma ótima oportunidade para se atualizar e expandir seu networking.",
    layout: "col-span-2",
  },
  {
    testimonial: "Viagem para a Casa FOA 2026, Buenos Aires, Argentina",
    description:
      "Descubra as tendências mais inspiradoras em arquitetura, design de interiores e paisagismo na renomada Casa FOA 2026. Participe de uma viagem única a Buenos Aires, onde você terá a oportunidade de explorar espaços transformadores, conhecer profissionais visionários e se conectar com o que há de mais inovador no mercado.",
    layout: "col-span-3",
  },
  {
    testimonial:
      "Viagem para o Congresso Mundial de Arquitetura da UIA em 2026, em Barcelona, Espanha",
    description:
      "Em 2026, Barcelona será o epicentro da arquitetura mundial, sediando o aguardado Congresso Mundial de Arquitetura da UIA. Junte-se a profissionais, acadêmicos e entusiastas para explorar tendências, inovações e soluções sustentáveis que moldarão o futuro da arquitetura e urbanismo.",
    layout: "col-span-5",
  },
];

export default function PrizesSection() {
  return (
    <section className="max-w-5xl mx-auto w-full px-10" id="prizes">
      <div className="flex items-center justify-center flex-col gap-y-2 py-5">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-white">
          Prêmios
        </h2>
        <p className="text-lg font-medium text-white">
          Os prêmios dessa edição foram pensados para enriquecer culturamente
          nossos grandes parceiros
        </p>
      </div>

      <div className="flex items-center justify-center flex-col lg:grid lg:grid-cols-5 gap-5 w-full">
        {prizesData.map((prize, index) => (
          <div
            key={index}
            className={`!border !p-7 !rounded-xl !bg-emerald-100 !drop-shadow-md !border-neutral-200/50 ${prize.layout} !flex !flex-col !gap-y-10 !justify-between`}
          >
            <div className="flex flex-col gap-y-3.5">
              <p className="!font-bold !text-xl !text-emerald-900">
                {prize.testimonial}
              </p>
              <p className="!font-medium !text-emerald-950">
                {prize.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
